
import { Component, Vue } from 'vue-property-decorator';
import { required } from 'vuelidate/lib/validators';
import parsePhoneNumber, { AsYouType } from 'libphonenumber-js';
import userService from '@/services/users/userService';
import businessService from '@/services/business/businessService';
import categoryService from '@/services/categories/categoryService';
import IBAN from 'iban';
import validator from 'validator';

@Component
export default class EditProfileForm extends Vue {
  public isInfoMsgActive = false;
  public showRna = false;
  public isFetchingSiret = false;
  public isSiretFound = true;
  public error = '';
  public commune = '';
  public category = '';
  public categories = [];
  public form: any = {
    ownerFirstName: '',
    ownerLastName: '',
    tradeName: '',
    // category: '',
    phoneNumber: '',
    businessName: '',
    siret: '',
    rna: '',
    address: {
      houseNumber: '',
      street: '',
      postalCode: '',
      city: '',
    },
    bankDetails: {
      iban: '',
      bic: '',
    },
  };

  $refs!: {
    siretRef: HTMLInputElement,
    rnaRef: HTMLInputElement,
  }

  private ownerData: any = {};

  get userId(): string {
    return this.$store.getters['authModule/userId'];
  }

  get formatedPhoneNumber(): string {
    return this.$v.form.phoneNumber?.$model;
  }

  set formatedPhoneNumber(phone: string) {
    const parsePN = parsePhoneNumber(phone, 'FR') as any;

    if (this.$v.form.phoneNumber && parsePN) {
      const formatedPhone = new AsYouType('FR').input(parsePN.number);
      this.$v.form.phoneNumber.$model = formatedPhone;
    }
  }

  toggleRnaField(): any {
    this.showRna = !this.showRna;
  }

  validations(): any {
    return {
      form: {
        ownerFirstName: {
          required,
        },
        ownerLastName: {
          required,
        },
        tradeName: {
          required,
        },
        phoneNumber: {
          required,
          isPhoneNumber: (value: string): boolean => {
            const phoneNumber = parsePhoneNumber(value, 'FR');
            const isValid = Boolean(phoneNumber && phoneNumber.isValid());

            return isValid;
          },
        },
        bankDetails: {
          iban: {
            required,
            isIBAN: (value: string): boolean => IBAN.isValid(value),
          },
          bic: {
            required,
            isBIC: (value: string): boolean => Boolean(value && validator.isBIC(value)),
          },
        },
        siret: {
          // required,
          isSiret: (value: string): boolean => {
            const size = 14;
            // Il faut soit un RNA soit un SIRET
            if (!value) return !!this.form.rna;
            if (Number.isNaN(value) || value.length !== size) return false;

            let bal = 0;
            let total = 0;

            for (let i = size - 1; i >= 0; i -= 1) {
              const step = (value.charCodeAt(i) - 48) * (bal + 1);

              total += step > 9 ? step - 9 : step;
              bal = 1 - bal;
            }
            if ((total % 10) !== 0) return false;
            return true;
          },
        },
        rna: {
          // required,
          isRna: (value: string): boolean => {
            // Commence par W + 9 caractères numériques
            const size = 10;
            // Il faut soit un RNA soit un SIRET
            if (!value) return !!this.form.siret;
            if (value.trim().length !== size) return false;
            if (value[0] !== 'W') return false;
            const rnaNumeric = value.trim().substring(1);
            if (Number.isNaN(rnaNumeric)) return false;
            console.log({ form: this.form, isSiretPresent: !!this.form.siret });
            return true;
          },
        },
        address: {
          street: { required },
          postalCode: {
            required,
            isPostalCode: (value: string): boolean => Boolean(value && validator.isPostalCode(value, 'FR')),
          },
          city: { required },
        },
        businessName: { required },
      },
      category: {
        required,
        // eslint-disable-next-line
        isIn: (value: string): boolean => Boolean(value && validator.isMongoId(value) && this.categories.some((category: { _id: string }) => category._id === value)),
      },
    };
  }

  async created(): Promise<void> {
    const { data } = await categoryService.getAll();
    this.categories = data;
    try {
      const owner = await userService.findOne(this.userId);
      this.form.ownerFirstName = owner.data.businessDetails.ownerFirstName;
      this.form.ownerLastName = owner.data.businessDetails.ownerLastName;
      this.form.tradeName = owner.data.businessDetails?.tradeName;
      // eslint-disable-next-line
      this.category = owner.data.category?._id;
      this.form.phoneNumber = owner.data.businessDetails.phoneNumber;
      this.form.bankDetails.bic = owner.data.businessDetails.bankDetails.bic;
      this.form.bankDetails.iban = owner.data.businessDetails.bankDetails.iban;
      this.form.siret = owner.data.businessDetails.siret;
      this.form.rna = owner.data.businessDetails.rna;
      this.form.businessName = owner.data.businessDetails.businessName;
      this.commune = owner.data.businessDetails.commune?.name;
      this.form.address = owner.data.businessDetails.address;
      this.form.address.city = owner.data.businessDetails.address.city;
      this.form.address.houseNumber = owner.data.businessDetails.address.houseNumber;
      this.form.address.street = owner.data.businessDetails.address.street;
      this.form.address.postalCode = owner.data.businessDetails.address.postalCode;
      this.ownerData.businessDetails = owner.data.businessDetails;
      if (owner.data.businessDetails.rna) {
        this.showRna = true;
      }
    } catch (error) {
      const { err } = error.response;

      this.error = Array.isArray(err.message) ? err.message[0] : err.message;
      this.isInfoMsgActive = true;
    }
  }

  async updateProfile(): Promise<void> {
    const loadingComponent = this.$buefy.loading.open({
      container: null,
    });

    this.isInfoMsgActive = false;
    try {
      this.ownerData.businessDetails.ownerFirstName = this.form.ownerFirstName;
      this.ownerData.businessDetails.ownerLastName = this.form.ownerLastName;
      this.ownerData.businessDetails.tradeName = this.form.tradeName;
      this.ownerData.category = this.category;
      this.ownerData.businessDetails.phoneNumber = this.form.phoneNumber;
      this.ownerData.businessDetails.businessName = this.form.businessName;
      this.ownerData.businessDetails.siret = this.form.siret;
      this.ownerData.businessDetails.rna = this.form.rna;
      this.ownerData.businessDetails.bankDetails.iban = this.form.bankDetails.iban;
      this.ownerData.businessDetails.bankDetails.bic = this.form.bankDetails.bic;
      this.ownerData.businessDetails.address = this.form.address;
      // eslint-disable-next-line no-underscore-dangle
      // this.ownerData.businessDetails.commune = this.ownerData.businessDetails.commune?._id;

      await userService.update(this.userId, this.ownerData);

      this.$buefy.snackbar.open({
        message: `
          Modification enregistrée <br>
        `,
        position: 'is-bottom-right',
        type: 'is-success',
        indefinite: false,
        actionText: 'ok',
      });
    } catch (error) {
      const { data } = error.response;

      this.error = Array.isArray(data.message) ? data.message[0] : data.message;
      this.isInfoMsgActive = true;
    } finally { loadingComponent.close(); }
  }

  get formatedIBAN(): string {
    return IBAN.printFormat(this.$v.form.bankDetails?.iban?.$model);
  }

  set formatedIBAN(iban: string) {
    if (this.$v.form.bankDetails?.iban) {
      this.$v.form.bankDetails.iban.$model = IBAN.electronicFormat(iban);
    }
  }
  async searchSiret(value: string): Promise<void> {
    if (this.$v.form.siret?.$error && this.$v.form.siret?.$dirty) {
      this.isSiretFound = false;
      this.form.address = {
        houseNumber: '',
        street: '',
        postalCode: '',
        city: '',
      };
      this.form.businessName = '';
      return;
    }
    this.isFetchingSiret = true;

    try {
      const dataFromApi = await businessService.getBySiret(value);
      if (+dataFromApi.status === 200 && !!dataFromApi.data) {
        this.form.address = dataFromApi.data.address;
        this.form.businessName = dataFromApi.data.businessName;
        this.isSiretFound = true;
        this.form.rna = '';
      } else {
        console.log({ dataFromApi });
        this.isSiretFound = false;
      }
    } catch (error) {
      this.isSiretFound = false;
      throw error;
    } finally {
      this.isFetchingSiret = false;
    }
  }
  async searchRna(value: string): Promise<void> {
    if (this.$v.form.rna?.$error && this.$v.form.rna?.$dirty) {
      this.isSiretFound = false;
      this.form.address = {
        houseNumber: '',
        street: '',
        postalCode: '',
        city: '',
      };
      this.form.businessName = '';
      return;
    }
    this.isFetchingSiret = true;

    try {
      const dataFromApi = await businessService.getByRna(value);
      if (+dataFromApi.status === 200 && !!dataFromApi.data) {
        this.form.address = dataFromApi.data.address;
        this.form.businessName = dataFromApi.data.businessName;
        this.isSiretFound = true;
        this.form.siret = '';
      } else {
        console.log({ dataFromApi });
        this.isSiretFound = false;
      }
    } catch (error) {
      this.isSiretFound = false;
      throw error;
    } finally {
      this.isFetchingSiret = false;
    }
  }
}
