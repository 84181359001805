
import { Component, Vue } from 'vue-property-decorator';
import EditProfileForm from '../components/EditProfileForm.vue';

@Component({
  components: {
    EditProfileForm,
  },
})
export default class Profile extends Vue {}
